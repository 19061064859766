import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EnableMonitoring } from '../../components/EnableMonitoring';
import { FiscalAuthorization } from '../../components/FiscalAuthorization';
import { Loading } from '../../components/Loading';
import { NoData } from '../../components/NoData';
import { Question } from '../../components/Question';
import { Requirements } from '../../components/Requirements';
import { SelectTypeExam } from '../../components/SelectTypeExam';
import { CertificationType } from '../../types/certifications/Certification';
import {
  useGetCertificationByIdMutation,
  useLoadEnrollQuery,
} from './certificationApiSlice';

export function Exam() {
  const params = useParams();
  const [fiscalApproved, setFiscalApproved] = useState(false);
  const [step, setSep] = useState(1);
  const [type, setType] = useState<CertificationType | null>(null);

  const [getCertification, { data: certificationItem, isLoading }] =
    useGetCertificationByIdMutation();

  const { data, isFetching } = useLoadEnrollQuery(
    { _id: params?.certificationId || '' },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  useEffect(() => {
    if (data?.[0].certification) {
      getCertification(data[0].certification);
    }
  }, [data, getCertification]);

  function handleTypeMonitoring(item: string) {
    if (type) return item === type;

    return certificationItem?.[0].monitoring === item;
  }

  function getTypeMonitoring() {
    if (type) {
      return type;
    }

    return certificationItem?.[0]?.monitoring
      ? certificationItem?.[0]?.monitoring
      : null;
  }

  if (isLoading) return <Loading />;
  if (!certificationItem?.[0]) return <NoData title="Sem dados" />;

  if (handleTypeMonitoring('select') && !type)
    return (
      <SelectTypeExam
        certification={certificationItem?.[0]}
        onChangeStep={e => {
          setType(e);
        }}
      />
    );

  if (
    (handleTypeMonitoring('video') || handleTypeMonitoring('fiscal')) &&
    step === 1
  )
    return (
      <Requirements
        certification={certificationItem?.[0]}
        onChangeStep={() => {
          setSep(2);
          setType(getTypeMonitoring());
        }}
      />
    );

  if ((handleTypeMonitoring('video') || type === 'video') && step === 2)
    return (
      <EnableMonitoring
        onChangeStep={() => {
          setSep(3);
          setType('video');
        }}
      />
    );

  if (handleTypeMonitoring('fiscal') && !fiscalApproved && step === 2)
    return (
      <FiscalAuthorization
        certificationId={data?.[0].certification || ''}
        certificationLog={data?.[0]?._id || ''}
        onFiscalAuthorization={setFiscalApproved}
      />
    );

  return (
    <div className="md:p-4  min-h-screen flex flex-col items-center">
      {isFetching ? '...refetching' : ''}

      <Question
        certification={certificationItem?.[0]}
        certificationLog={data?.[0]}
        type={type}
      />
    </div>
  );
}
